import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Hero from '../components/Hero';

import about from '../images/about.jpg';

const About = ({ data }) => {
  const { wpWebdata } = data;
  const attributes = wpWebdata;

  return (
    <Layout pageTitle={attributes.shibuya.titleAboutUs} copyright={attributes.shibuya.copyright}>
      <Hero backgroundImage={about} attributes={attributes} />
      <article className="article">
        <h1>{attributes.shibuya.aboutUs}</h1>
        <p>{attributes.shibuya.aboutUsLine1}</p>
        <p>{attributes.shibuya.aboutUsLine2}</p>
        <p>{attributes.shibuya.aboutUsLine3}</p>
        <p>{attributes.shibuya.aboutUsLine4}</p>
        <p>{attributes.shibuya.aboutUsLine5}</p>
      </article>
    </Layout>
  );
};

export default About;

About.propTypes = {
  data: PropTypes.shape({
    wpWebdata: PropTypes.shape({
      title: PropTypes.string,
      shibuya: PropTypes.shape({
        aboutUs: PropTypes.string,
        aboutUsLine1: PropTypes.string,
        aboutUsLine2: PropTypes.string,
        aboutUsLine3: PropTypes.string,
        aboutUsLine4: PropTypes.string,
        aboutUsLine5: PropTypes.string,
        titleHome: PropTypes.string,
        titleAboutUs: PropTypes.string,
        titleMenu: PropTypes.string,
        titleReserve: PropTypes.string,
        titleFaq: PropTypes.string,
        titleContact: PropTypes.string,
        copyright: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export const query = graphql`
query ($language: String!) {
  wpWebdata(title: {eq: $language}) {
     title
     shibuya {
       aboutUs
       aboutUsLine1
       aboutUsLine2
       aboutUsLine3
       aboutUsLine4
       aboutUsLine5
       titleHome
       titleAboutUs
       titleMenu
       titleReserve
       titleFaq
       titleContact
       copyright
     }
  }
}
`;
